import request from '@/utils/request'

//查询赎回份额
export function queryUserShare(data) {
    return request({
        url: "/api/sale/trade/position/queryUserShare",
        method: "POST",
        data
    });
}


//查询赎回银行卡
export function queryBankCard(data) {
    return request({
        url: "/api/sale/trade/position/queryBankCard",
        method: "POST",
        data
    });
}

//赎回请求
export function redeemTrade(data) {
    return request({
        url: "/api/sale/trade/position/redeemTrade",
        method: "POST",
        data
    });
}

//惠金宝可转入份额
export function enableShare(data) {
    return request({
        url: "/api/sale/trade/xjb/enableShare",
        method: "POST",
        data
    });
}


//基金转换
export function conversion(data) {
    return request({
        url: "/api/sale/trade/fund/conversion",
        method: "POST",
        data
    });
}

// 其他基金赎回现金宝
export function prodRedeemXjb(data) {
    return request({
      url: '/api/sale/trade/xjb/prodRedeemXjb',
      method: 'POST',
      data
    })
  }