// author:马云阳；description:众惠-卖出
<template>
    <section class="PublicFundProcess ">
        <site-nav>
          <span>&gt;</span>
          <RouterLink :to="{name:'FundMarket'}">基金超市</RouterLink>
          <span>&gt;</span>
          <span>{{funddatas.fund.fundName}}</span>
        </site-nav>
        <!-- <NavRightSide/> -->
        <div class="publicfundprocess_cont fixed_width">
            <a @click="toGo" class="title_">
             <span class="iconfont">&#xe665;</span> {{funddatas.fund.fundName}}
            </a>
            <div class="side_cont">
              <ProgressBar :sideData="side"/>
            </div>
            <div class="solids_"></div>
           <div class="card_">
              <div class="turn_">
                  <div class="card_s left_card">
                    <img class="card_ti" src="@/static/img/my/gm_out_.png" alt="">
                    <div class="card_txt">
                      <div class="txt_">
                        <div class="left_txt">卖出基金:</div>
                        <div class="right_txt">{{funddatas.fund.fundName}}</div>
                      </div>
                      <div class="txt_">
                        <div class="left_txt">可用份额:</div>
                        <div class="right_txt">{{funddatas.shares}}份</div>
                      </div>
                      <div class="txt_">
                        <div class="left_txt">转出份额:</div>
                        <div class="right_txt">{{funddatas.num}}份</div>
                      </div>
                    </div>
                  </div>
              </div>
              <div class="inputs_">
                <div class="title_input">
                  <span class="l_ti">请输入交易密码:</span>
                  <a class="r_ti" @click="forgetPoss">忘记密码?</a>
                </div>
                <div class="inputs_boxs">
                  <input style="width:100%;height:100%;" :maxlength="6" v-model="password" placeholder="请输入六位交易密码" type="password">
                </div>
                <span v-if="showerr" class="err">{{errmsg}}</span>

              </div>
              <div class="topage_btn">
                <a @click="getbtn?configs():''" class="topage_">确定</a>
                <a @click="toGo" class="navback">返回上一步</a>
              </div>
          </div>
        </div>
    </section>
</template>
<script>
// import NavRightSide from "@/components/NavRightSide";
import ProgressBar from "@/components/ProgressBar";
import { redeemTrade ,prodRedeemXjb} from "@/api/outfund";
import SiteNav from "@/views/fund-market/components/SiteNav.vue";

export default {
  name: "ProcessSellOutPassword",
  components: { ProgressBar, SiteNav },
  data() {
    return {
      side: [
        { title: "选择卖出基金", type: 1 },
        { title: "填写卖出信息", type: 1 },
        { title: "输入卖出密码", type: 2 },
        { title: "交易结果", type: 0 }
      ],
      funddatas: {}, //数据
      userinfo: {}, //用户数据
      password: "",
      showerr: false,
      errmsg: "",
      getbtn: true
    };
  },
  methods: {
    forgetPoss() {
      this.$router.push("/myFavors/accountManage/safetyCentre");
    },
    topeges() {
      this.$router.go(-2);
    },
    configs() {
      if (!this.password || this.password.length < 6) {
        this.$message.error('请正确输入密码！')
      } else {
        this.getbtn = false;
        if (this.funddatas.type=='1') {
          this.getbtn = false;
          prodRedeemXjb({
          	share:this.funddatas.num,
          	fundCode:this.funddatas.fund.fundCode,
            tradePassword:this.password,
            oppoTradeAcco:this.funddatas.banklist.tradeAcco,
            tradeAcco:this.funddatas.tradeAcco,
            orderTransferDate:this.funddatas.fundTransferDate,
          }).then(res=>{
            if (res.code != 200) {
                this.$message.error(res.msg);
              } else {
                this.showerr = false;
                let date = new Date();
                let okey = { funddatas: this.funddatas, dates: date };
                this.$router.push({
                  name: "SellOutFundfour",
                  params: { okey }
                });
              }
          }).catch(e => {
              this.getbtn = true;
              this.showerr = true;
              this.errmsg = e;
            });
        } else {
          this.getbtn = false;
          let params = {
            tradeAcco: this.funddatas.banklist.tradeAcco,
            password: this.password,
            fundCode: this.funddatas.fund.fundCode,
            applyShare: this.funddatas.num,
            orderTransferDate:this.funddatas.fundTransferDate,
            clientSource: "1"
          };
          console.log('-----',params)
          redeemTrade(params)
            .then(res => {
              if (res.code != 200) {
                this.$message.error(res.msg);
              } else {
                this.showerr = false;
                let date = new Date();
                let okey = { funddatas: this.funddatas, dates: date };
                this.$router.push({
                  name: "SellOutFundfour",
                  params: { okey }
                });
              }
            })
            .catch(e => {
              this.getbtn = true;
              this.showerr = true;
              this.errmsg = e;
            });
        }
      }
    },
    toGo() {
      this.$router.go(-1);
    }
  },
  created() {
    this.funddatas = this.$route.params.params;
    this.userinfo =
      JSON.parse(window.localStorage.getItem("customerInfo")) || {};
    console.log("ids", this.funddatas);
  }
};
</script>
<style lang="less" scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"] {
  -moz-appearance: textfield;
}
::-webkit-input-placeholder {
  color: #bdc0cb;
}
::-moz-placeholder {
  color: #bdc0cb;
}

/* firefox 19+ */
:-ms-input-placeholder {
  color: #bdc0cb;
}

/* ie */
input:-moz-placeholder {
  color: #bdc0cb;
}

.PublicFundProcess {
  // padding: 0px 0px 50px 0;
  min-height: 600px;
  // display: flex;
  background: #f3f4f6;
  .publicfundprocess_cont {
    background: #fff;
    width: 1200px;
    .title_ {
      display: inline-block;
      padding: 40px 40px 0px 40px;
      height: 60px;
      font-size: 20px;
      color: #1f1f1f;
    }
    .side_cont {
      height: 146px;
      padding: 0px 0px 0px 80px;
      display: flex;
      align-items: center;
    }
    .solids_ {
      height: 2px;
      background: linear-gradient(270deg, #eabf96 0%, #f7d9b7);
      box-shadow: 0px 4px 12px 0px #edf1f7;
    }
    .card_ {
      padding: 0px 0 440px 217px;
      margin-top: 30px;
      .titles_ {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 18px;
        font-family: Microsoft YaHei UI, Microsoft YaHei UI-Bold;
        font-weight: 700;
        color: #1f1f1f;
      }
      .turn_ {
        margin-top: 70px;
        display: flex;
        align-items: center;

        .card_s {
          position: relative;
          width: 357px;
          height: 220px;
          &:first-child {
            height: 155px;
            padding-top: 65px;
          }
          &:last-child {
            height: 165px;
            padding-top: 55px;
          }
          .card_ti {
            position: absolute;
            width: 105px;
            height: 60px;
            top: -30px;
            z-index: 99;
            left: 135px;
          }
        }
        .left_card {
          background: linear-gradient(121deg, #4e494d 0%, #0b0409 100%);
          border-radius: 4px;
          margin-right: 60px;
          .card_txt {
            .txt_ {
              display: flex;
              font-size: 16px;
              font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
              font-weight: 400;
              color: #ffffff;
              justify-content: center;
              margin-bottom: 22px;
              .left_txt {
                text-align: right;
                width: 37%;
                padding-right: 3%;
              }
              .right_txt {
                width: 60%;
                text-align: left;
              }
            }
          }
        }
        .right_card {
          background: linear-gradient(296deg, #cca376 0%, #f2e2d1 100%);
          border-radius: 4px;
          .card_txt {
            .txt_ {
              display: flex;
              font-size: 16px;
              font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
              font-weight: 400;
              color: #ffffff;
              justify-content: center;
              margin-bottom: 20px;
              .left_txt {
                text-align: right;
                width: 47%;
                padding-right: 3%;
              }
              .right_txt {
                width: 50%;
                text-align: left;
              }
            }
          }
        }
      }
      .inputs_ {
        width: 520px;
        margin-top: 40px;
        .title_input {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .l_ti {
            font-size: 16px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            color: #1f1f1f;
          }
          .r_ti {
            font-size: 14px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            color: #a5a5a5;
          }
        }
        .inputs_boxs {
          display: flex;
          align-items: center;
          width: 494px;
          height: 46px;
          border: 1px solid #e0e2e8;
          border-radius: 2px;
          padding-left: 24px;
          margin-top: 14px;
        }
      }
      .topage_btn {
        margin-top: 40px;
        display: flex;
        align-items: center;
        .topage_ {
          display: inline-block;
          width: 240px;
          height: 48px;
          line-height: 48px;
          text-align: center;
          color: #ce9b63;
          background: linear-gradient(101deg, #f7d9b7 0%, #eabf96 100%);
          border-radius: 2px;
          font-size: 16px;
          margin-right: 20px;
        }
        .navback {
          font-size: 16px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          color: #ce9b63;
        }
      }
    }
  }
}
</style>
